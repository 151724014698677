import React from 'react';

function Logo(props) {
  return (
    <img
      alt={'bgood'}
      src={'/logo.png'}
      height={'48'}
      {...props}
    />
  );
}

export default Logo;
