import 'core-js';
import 'react-app-polyfill/ie11';
import 'formdata-polyfill';
import 'css-vars-ponyfill';


import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';

import NoPage from '../../components/404';
import Loading from '../../components/Loading';
import Confirm from '../../components/Confirm';

import EndConfirm from '../../components/EndConfirm';

import {db} from '../../lib/firebase';

import AppPage from './App';
import Prepage from './Prepage';

export default function App() {
    const crowdId = window.location.pathname.split('?')[0].replace(/\//g, '-').slice(1,);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [attend, setAttend] = useState(false);
    const [crowd, setCrowd] = useState(null);
    const [error, setError] = useState(null);
    const handleAttend = () => {
        setAttend(true);
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        setLoading(true);
        setCrowd(null);
        if (crowdId.length === 0) {
            enqueueSnackbar('잘못된 접근입니다', {variant: 'error'});
            return null;
        }
        db.ref(`crowd/${crowdId}`).once('value')
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setCrowd(({
                        id: crowdId,
                        ...snapshot.val()
                    }));
                }
            })
            .catch((e) => {
                setError(e);
                setCrowd(null);
                enqueueSnackbar('데이터를 불러오는데 문제가 발생했습니다.', {variant: 'error'});
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
        };
    }, [crowdId]);

    if (loading) {
        return <Loading/>;
    }
    if (!crowd) {
        return <NoPage/>;
    }
    if (crowd.closed) {
        return (
            <EndConfirm
                crowd={crowd}
            />);
    }
    if (!attend && crowd.prepage) {
        return (
            <Prepage
                prepage={crowd.prepage}
                onClickButton={handleAttend}
            />
        );
    }

    return (
        <AppPage
            crowd={crowd}
        />
    );

}
