import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Input from './Input';

import {db} from '../lib/firebase';

import Label from './Label';

const statuses = {
    payment: ['이체 확인 중', 'warning'],
    confirm: ['확인 완료', 'primary'],
    prepare: ['준비 중', 'secondary'],
    cancel: ['취소', 'error'],
    refund: ['환불 완료', 'secondary']
};

const Status = ({status}) => {
    const label = statuses[status] ?? ['확인 중', 'warning'];
    return (
        <Label
            color={label[1]}
        >
            {label[0]}
        </Label>
    );
};


const useStyles = makeStyles(theme => ({
    bg: {
        minWidth: '100vw',
        minHeight: '100vh',
        marginTop: theme.spacing(-8)
    },
    container: {
        marginTop: theme.spacing(15),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    fullHeight: {
        height: '100%',
    },
    img: {
        width: 100,
        margin: theme.spacing(2, 0)
    },
    text: {
        fontWeight: 'bold'
    }

}));

const Confirm = () => {
    const pathname = window.location.pathname.split('?')[0].replace(/\//g, '-').slice(1,);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [input, setInput] = useState('');
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (window && window.scrollTo)
            window?.scrollTo(0, 0);
    }, []);

    const handleInput = (e) => {
        const tel = e.target.value;
        const numberTel = tel.replace(/[^\d]/g, '');
        const newTels = [];
        if (numberTel.length <= 3) {
            newTels[0] = numberTel;
        }
        if (numberTel.length > 3) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
        }
        if (numberTel.length > 7) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
            newTels[2] = numberTel.slice(7, numberTel.length);
        }
        setInput(newTels.join('-'));
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleSearch = (e) => {
        db.ref(`${pathname}`).once('value').then(snapshot => {
            if (snapshot.exists()) {
                setCrowd(snapshot.val());
            }
        });
        db.ref(`applies`)
            .orderByChild('tel').equalTo(input)
            .once('value')
            .then(snapshot => {
                if (snapshot.exists()) {
                    setResults(Object.entries(snapshot.val()));
                }
            });
    };
    const handleCancelBuild = (key) => () => {
        if (window.confirm('주문을 취소하시겠습니까? 1일이 내 환불처리 완료됩니다.'))
            db.ref(`applies/${key}/status`).set('cancel')
                .then((e) => {
                    enqueueSnackbar('취소 요청이 완료되었습니다.. ', {variant: 'info'});
                })
                .then((e) => {
                    db.ref(`applies`)
                        .orderByChild('tel').equalTo(input)
                        .once('value')
                        .then(snapshot => {
                            if (snapshot.exists()) {
                                setResults(Object.entries(snapshot.val()));
                            }
                        });
                })
                .catch((e) => {
                    enqueueSnackbar('취소 요청에 실패했습니다. ', {variant: 'error'});
                });
    };
    return (
        <Box
            className={classes.bg}
        >
            <Container
                className={classes.container}
                maxWidth={'lg'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mt={2}
                    mb={5}
                >
                    <Typography
                        variant={'h4'}
                    >
                        주문 조회 / 확인 / 취소
                    </Typography>
                    <Button
                        color={'primary'}
                        onClick={handleRefresh}
                    >
                        새 주문하기
                    </Button>
                </Box>
                <Box
                    mt={2}
                    mb={2}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={10}
                        >
                            <Input
                                fullWidth
                                label={'휴대폰번호'}
                                placeholder={'000-0000-0000'}
                                value={input}
                                onChange={handleInput}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Box
                                height={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Button
                                    fullWidth
                                    className={classes.fullHeight}
                                    color={'secondary'}
                                    variant={'outlined'}
                                    disabled={!input}
                                    onClick={handleSearch}
                                >
                                    조회
                                </Button>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    주문자
                                </TableCell>
                                <TableCell>
                                    연락처
                                </TableCell>
                                <TableCell>
                                    주소
                                </TableCell>
                                <TableCell>
                                    수량
                                </TableCell>
                                <TableCell>
                                    결제금액
                                </TableCell>
                                <TableCell>
                                    상태
                                </TableCell>
                                <TableCell>
                                    주문일
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                results.map(([key, apply]) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                {apply.name}
                                            </TableCell>
                                            <TableCell>
                                                {apply.tel}
                                            </TableCell>
                                            <TableCell>
                                                {apply.postalCode} {apply.address1} {apply.address2}
                                            </TableCell>
                                            <TableCell>
                                                {apply.amount}
                                            </TableCell>
                                            <TableCell>
                                                {(apply.price).toLocaleString()}원
                                            </TableCell>
                                            <TableCell>
                                                <Status status={apply.status}/>
                                            </TableCell>
                                            <TableCell>
                                                {new Date(apply.orderedAt).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    apply.status !== 'cancel'
                                                    && apply.status !== 'refund'
                                                        ? (
                                                            <Button
                                                                onClick={handleCancelBuild(key)}
                                                            >
                                                                주문 취소
                                                            </Button>
                                                        )
                                                        : null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                        </TableBody>
                    </Table>
                    {
                        results.length > 0
                            ? (
                                <Box mt={2}>
                                    <Typography>
                                        이체계좌:
                                        국민은행 407501-01-204108 주식회사 에스앤이컴퍼니
                                    </Typography>
                                    <Typography>
                                        문의: (주)에스앤이컴퍼니 1666-3812
                                    </Typography>
                                </Box>
                            )
                            : null
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default Confirm;
