import React from 'react';

import {
    Container,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0,
        margin: 'auto'
    },
    img: {
        width: '100%',
        display: 'block',
        padding: 0,
        margin: 0
    }
}));

export default function ({prepage, onClickButton}) {
    const classes = useStyles();
    const handleClick = () => {
        onClickButton();
    };
    return (
        <Container
            className={classes.container}
            maxWidth={'sm'}
        >
            <img
                className={classes.img}
                src={prepage['top']}
            />
            <img
                onClick={handleClick}
                className={classes.img}
                src={prepage['button']}
            />
            <img
                className={classes.img}
                src={prepage['bottom']}
            />
        </Container>
    );
}