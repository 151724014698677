import clsx from 'clsx';
import React, {useEffect} from 'react';
import Input from './Input';

const KakaoAddress = ({
                          className,
                          id,
                          type,
                          placeholder,
                          label,
                          error,
                          onChange,
                          value
                      }) => {
    useEffect(() => {
        if (!document) return;
        const script = document.createElement('script');
        script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onAddress = (addressLoad) => {
        const postalCode = addressLoad.zonecode;
        const address1 = addressLoad.address;
        onChange({
            postalCode, address1, address2: ''
        });
    };
    const openKakaoMap = () => {
        if (window.daum) {
            new window.daum.Postcode({oncomplete: onAddress}).open();
        }
    };

    return (
        <Input
            fullWidth
            id={id}
            className={className}
            type={type}
            label={label}
            placeholder={'클릭해 주소 찾기'}
            onChange={onChange}
            value={value}
            onClick={openKakaoMap}
            variant={'outlined'}
            error={error}
        />
    );
};
KakaoAddress.defaultProps = {
    value: ''
};
export default KakaoAddress;
