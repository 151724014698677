import {red} from '@material-ui/core/colors';
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';


const payboocExtraBold = {
    fontFamily: 'Paybooc',
    fontStyle: 'normal',
    fontWeight: '800',
    src: `
    url("/fonts/payboocExtraBold.otf") format('opentype')
    `
};
const payboocBold = {
    fontFamily: 'Paybooc',
    fontStyle: 'normal',
    fontWeight: '700',
    src: `
    url("/fonts/payboocBold.otf") format('opentype')
  `
};
const payboocMedium = {
    fontFamily: 'Paybooc',
    fontStyle: 'normal',
    fontWeight: '500',
    src: `
    url("/fonts/payboocMedium.otf") format('opentype')
  `
};
const payboocLight = {
    fontFamily: 'Paybooc',
    fontStyle: 'normal',
    fontWeight: '300',
    src: `
    url("/fonts/payboocLight.otf") format('opentype')
  `
};
// A custom theme for this app
const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [payboocExtraBold, payboocBold, payboocMedium, payboocLight],
            },
        },
    },
    typography: {
        fontFamily: [
            'Paybooc',
            'Noto Sans KR',
            'Roboto',
            'sans-serif'
        ]
    },
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
});

export default responsiveFontSizes(theme);
