import React, {lazy} from 'react';
import App from '../pages/app';
import Admin from '../pages/admin';
import Confirm from '../pages/confirm';
export default [
    {
        title: 'B·good 공동구매',
        path: '/confirm',
        exact: true,
        page: Confirm,
    },
    // {
    //     title: 'B·good 공동구매',
    //     path: '/admin',
    //     exact: true,
    //     page: Admin,
    // },
    {
        title: 'B·good 공동구매',
        path: '*',
        exact: false,
        page: App
    }
];
