import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    container: {
        minWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(-8)
    },
    img: {
        width: 100,
        margin: theme.spacing(2, 0)
    },
    text: {
        fontWeight: 'bold'
    }

}));

const NoPage = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography className={classes.text} variant={'body1'} color={'primary'} align={'center'}>
                해당 내용을 찾을 수 없습니다 (404)
            </Typography>
        </Box>
    );
};

export default NoPage;
