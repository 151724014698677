import 'core-js';
import 'react-app-polyfill/ie11';
import 'formdata-polyfill';
import 'css-vars-ponyfill';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import App from './App';
import theme from './theme';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
        <App/>
    </ThemeProvider>,
    document.querySelector('#root'),
);
