import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    container: {
        minWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(-8)
    },
    bar: {
        width: 300,
    }

}));

const Loading = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <LinearProgress
                className={classes.bar}
            />
        </Box>
    );
};

export default Loading;
