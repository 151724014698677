import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyDtXbL7zmjKWmC5dR7zc-z5AY7XEKa-rZs',
    authDomain: 'bgood-app.firebaseapp.com',
    databaseURL: 'https://bgood-app.firebaseio.com',
    projectId: 'bgood-app',
    storageBucket: 'bgood-app.appspot.com',
    messagingSenderId: '85989842413',
    appId: '1:85989842413:web:a40ba05e30c701a8385be4'
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const db = firebase.database();