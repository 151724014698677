import React from 'react';
import {Redirect, Route as ReactRouterRoute, useLocation} from 'react-router';
import ScrollToTop from './ScrollToTop';
import ErrorBoundary from './ErrorBoundary';


const Route = ({page: Page, path, ...props}) => {
    const renderComponent = () => (
        <ScrollToTop>
            <Page/>
        </ScrollToTop>
    );
    return (
        <ErrorBoundary error={<p>Page has a error</p>}>
            <ReactRouterRoute
                exact
                path={path}
                render={renderComponent}/>
        </ErrorBoundary>
    );
};


export default Route;

Route.defaultProps = {};