import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Input from '../../components/Input';

import {db} from '../../lib/firebase';

import Label from '../../components/Label';

import bankCodes from '../../constants/bankCodes';


const statuses = {
    payment: ['이체 확인 중', 'warning'],
    confirm: ['확인 완료', 'primary'],
    prepare: ['준비 중', 'secondary'],
    cancel: ['취소', 'error'],
    refund: ['환불 완료', 'secondary']
};

const Status = ({status}) => {
    const label = statuses[status] ?? ['확인 중', 'warning'];
    return (
        <Label
            color={label[1]}
        >
            {label[0]}
        </Label>
    );
};


const useStyles = makeStyles(theme => ({
    bg: {
        minWidth: '100vw',
        minHeight: '100vh',
        marginTop: theme.spacing(-8)
    },
    container: {
        marginTop: theme.spacing(15),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    fullHeight: {
        height: '100%',
    },
    img: {
        width: 100,
        margin: theme.spacing(2, 0)
    },
    text: {
        fontWeight: 'bold'
    }

}));

const ConfirmAdmin = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [results, setResults] = useState([]);

    useEffect(() => {
        db.ref(`applies`).on('value', (snapshot) => {
            if (snapshot.exists()) {
                setResults(Object.entries(snapshot.val())
                    .filter(([key, order]) => order.crowd.endsWith('210401'))
                    .sort(([_, {orderedTime: orderedTime1}], [__, {orderedTime: orderedTime2}]) => +(orderedTime2) - +(orderedTime1))
                );
            }
        });
    }, []);

    const handleConfirmBuilder = (key) => () => {
        db.ref(`applies/${key}/status`).set('confirm');
        enqueueSnackbar('입금확인');
    };
    const reduced = results.reduce((rslt, [_, item]) => {
        rslt['total'] += item.amount;
        if (item.crowd.startsWith('snecompany'))
            rslt['snecompany'] += item.amount;
        else if (item.crowd.startsWith('seoulpress'))
            rslt['seoulpress'] += item.amount;
        else if (item.crowd.startsWith('kdic'))
            rslt['kdic'] += item.amount;
        return rslt;
    }, {
        total: 0,
        snecompany: 0,
        seoulpress: 0,
        kdic: 0,
    });
    return (
        <Box
            className={classes.bg}
        >
            <Container
                className={classes.container}
                maxWidth={'lg'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mt={2}
                    mb={5}
                >
                </Box>
                <Box>

                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography>
                                        전체 :{reduced?.total}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>

                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                시
                            </TableCell>
                            <TableCell>
                                그룹
                            </TableCell>
                            <TableCell>
                                주문자
                            </TableCell>
                            <TableCell>
                                계좌정보
                            </TableCell>
                            <TableCell>
                                연락처
                            </TableCell>
                            <TableCell>
                                수량
                            </TableCell>
                            <TableCell>
                                결제금액
                            </TableCell>
                            <TableCell>
                                상태
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            results.map(([key, apply]) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            {apply.crowd}
                                        </TableCell>
                                        <TableCell>
                                            {apply.group}
                                        </TableCell>
                                        <TableCell>
                                            {apply.name}
                                        </TableCell>
                                        <TableCell>
                                            ({bankCodes[apply.accountBank]}) {apply.accountHolder} {apply.accountNumber}
                                        </TableCell>
                                        <TableCell>
                                            {apply.tel}
                                        </TableCell>
                                        <TableCell>
                                            {apply.postalCode} {apply.address1} {apply.address2}
                                        </TableCell>
                                        <TableCell>
                                            {apply.amount}
                                        </TableCell>
                                        <TableCell>
                                            <Status status={apply.status}/>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                apply.status !== 'cancel'
                                                && apply.status !== 'refund'
                                                    ? (
                                                        <Button
                                                            onClick={handleConfirmBuilder(key)}
                                                        >
                                                            입금 확인
                                                        </Button>
                                                    )
                                                    : null
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </Container>
        </Box>
    );
};

export default ConfirmAdmin;
