export default {
    '003': '기업은행',
    '004': '국민은행',
    '011': '농협',
    '081': '하나은행',
    '088': '신한은행',
    '020': '우리은행',
    '089': '케이뱅크',
    '090': '카카오뱅크',
    '023': 'SC제일은행',
    '045': '새마을금고',
    '007': '수협은행',
    '048': '신협',
    '071': '우체국',
    '037': '전북은행',
    '034': '광주은행',
    '039': '경남은행',
    '032': '부산은행',
    '031': '대구은행',
    '035': '제주은행',
    '002': '산업은행',
    '050': '상호저축은행'
};
