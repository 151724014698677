import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Logo from '../../components/Logo';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import KakaoAddress from '../../components/KakaoAddress';
import CheckLabel from '../../components/CheckLabel';
import bankCodes from '../../constants/bankCodes';
import availablePostalCodes from '../../constants/availablePostalCodes';
import company from '../../constants/company';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

import {db} from '../../lib/firebase';


const defaultSchema = {
    name: Yup.string().required('주문자 성함을 입력해주세요.'),
    tel: Yup.string().matches(/01\d-\d{3,4}-\d{4}/, '올바른 휴대폰 번호가 아닙니다.').required('이용자 휴대폰 번호 입력해주세요.'),
    email: Yup.string().required('주문자 이메일을 입력해주세요.'),
    amount: Yup.number().min(1, '최소 한 개 이상 주문 되어야 합니다.'),
};

const deliverySchema = {
    postalCode: Yup.string().length(5, '우편번호는 5자리입니다.').required('배송지 우편번호가 필요합니다.'),
    address1: Yup.string().required('배송지 주소가 필요합니다.'),
    address2: Yup.string(),
    deliveryName: Yup.string().required('받으시는 분 성함이 필요합니다.'),
    deliveryTel: Yup.string().required('받으시는 분 연락처가 필요합니다.'),
    deliveryRequirement: Yup.string().required('새벽 배송 정보가 필요합니다.')
};

const paymentSchema = {
    accountBank: Yup.string().min(3, '은행 선택이 잘못 되었습니다.').required('은행을 선택해주세요.'),
    accountHolder: Yup.string().min(2, '입금하실 예금주명을 확인해주십시오').required('예금주명을 입력해주세요.'),
    accountText: Yup.string(),
    billTo: Yup.string(),
};

const defaultValues = {
    name: '',
    tel: '',
    email: '',
    amount: 1,
};

const deliveryValues = {
    postalCode: '',
    address1: '',
    address2: '',
    deliveryName: '',
    deliveryTel: '',
    deliveryRequirement: '',
};

const paymentValues = {
    accountBank: '004',
    accountHolder: '',
    accountText: '',
    billTo: '',
};


const useStyles = makeStyles(theme => ({
    bg: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: '#fbfafa',
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    productInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3)
    },
    logo: {
        height: 40,
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1, 0),
        }
    },
    sectionSubtitle: {
        marginTop: theme.spacing(1)
    },
    card: {
        margin: theme.spacing(2, 0)
    },
    cardMedia: {
        height: 240,
        [theme.breakpoints.down('xs')]: {
            height: 180
        }
    },
    img: {
        display: 'block',
        width: '100%',
        overflow: 'hidden'
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: '600',
        // whiteSpace: 'pre'
    },
    subtitle: {
        marginBottom: theme.spacing(1),
        fontWeight: '600',
        // whiteSpace: 'pre'
    },
    groupTitle: {
        marginBottom: theme.spacing(2),
        fontWeight: '600',
        // whiteSpace: 'pre'

    },
    typo: {
        marginTop: theme.spacing(1),
        fontWeight: '600',
        // whiteSpace: 'pre'
    },

    bold: {
        fontWeight: '600'
    },
    inputs: {
        marginBottom: theme.spacing(-2),
    },
    input: {
        marginBottom: theme.spacing(2)
    },
    actions: {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
    },
    actionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    price: {
        fontWeight: '600'
    },
    codeHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    bottom: {
        marginTop: theme.spacing(4)
    }
}));


function composeSchemas(payment, delivery) {
    return {
        schemas: Yup.object().shape({
            ...defaultSchema,
            ...(payment ? paymentSchema : {}),
            ...(delivery ? deliverySchema : {})
        }),
        initialValues: {
            ...defaultValues,
            ...(payment ? paymentValues : {}),
            ...(delivery ? deliveryValues : {})
        }
    };
}

export default function App({crowd,}) {
    const classes = useStyles();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [agreement, setAgreement] = useState(false);
    const [expandAgreement, setExpandAgreement] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [expandPrivacy, setExpandPrivacy] = useState(false);
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const groupId = searchParams.get('group') ?? 'etc';
    const group = crowd && crowd.groups ?
        !!crowd.groups[groupId]
            ? crowd.groups[groupId]
            : crowd.groups['etc']
        : null;

    const disablePrice = (group?.paid) && (group?.disablePrice);
    const disableModal = (group?.disableModal);

    const {schemas: validationSchema, initialValues} = composeSchemas(!group?.paid, !crowd?.groupOrder);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {setErrors, setStatus, setSubmitting}) => {
            if (window?.confirm('주문 완료 되었습니다.')) {
                try {
                    validationSchema.validateSync(values);
                } catch (e) {
                    setErrors({submit: '입력정보가 잘못되었습니다.'});
                    setStatus({success: false});
                    setSubmitting(false);
                    enqueueSnackbar(e.message, {variant: 'error'});
                }
                try {
                    await db.ref(`/applies`).push({
                        ...values,
                        price: crowd.price * values.amount,
                        crowd: crowd.id,
                        status: 'payment',
                        group: groupId,
                        orderedAt: (new Date()).toUTCString(),
                        orderedTime: (new Date()).getTime()
                    });
                    setStatus({success: true});
                    setSubmitting(false);

                    await db.ref(`crowd/${crowd.id}/count/`).transaction((count) => {
                        if (!count) return 1;
                        return count + 1;
                    });

                    await db.ref(`crowd/${crowd.id}/groups/${groupId}/count`).transaction((count) => {
                        if (!count) return 1;
                        return count + 1;
                    });

                    enqueueSnackbar('주문이 완료되었습니다.', {variant: 'info'});
                    history.push('/confirm');
                } catch (e) {
                    console.error(e);
                    setErrors({submit: '죄송합니다. 주문을 진행할 수 없습니다.'});
                    setStatus({success: false});
                    setSubmitting(false);
                    enqueueSnackbar('죄송합니다. 주문을 진행할 수 없습니다.', {variant: 'error'});
                }
            } else {
                enqueueSnackbar('주문 취소', {variant: 'warning'});
            }
        }
    });

    const {handleSubmit, handleChange, handleBlur, setFieldValue, setValues, values, errors, touched} = formik;

    const hadTouched = Object.entries(touched).length > 0;
    const hasError = Object.entries(errors).length > 0;

    const handleName = (e) => {
        const name = e.target.value;
        setFieldValue('name', name);
        if (!touched.deliveryName)
            setFieldValue('deliveryName', name);
    };

    const handleTel = (e) => {
        const tel = e.target.value;
        const numberTel = tel.replace(/[^\d]/g, '');
        const newTels = [];
        if (numberTel.length <= 3) {
            newTels[0] = numberTel;
        }
        if (numberTel.length > 3) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
        }
        if (numberTel.length > 7) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
            newTels[2] = numberTel.slice(7, numberTel.length);
        }
        setFieldValue('tel', newTels.join('-'));
        if (!touched.deliveryTel)
            setFieldValue('deliveryTel', newTels.join('-'));
    };

    const handleDeliveryTel = (e) => {
        const tel = e.target.value;
        const numberTel = tel.replace(/[^\d]/g, '');
        const newTels = [];
        if (numberTel.length <= 3) {
            newTels[0] = numberTel;
        }
        if (numberTel.length > 3) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
        }
        if (numberTel.length > 7) {
            newTels[0] = numberTel.slice(0, 3);
            newTels[1] = numberTel.slice(3, 7);
            newTels[2] = numberTel.slice(7, numberTel.length);
        }
        setFieldValue('deliveryTel', newTels.join('-'));
    };

    const handleEmail = (e) => {
        const email = e.target.value;
        setFieldValue('email', email.trim());
    };

    const handleAccountHolder = (e) => {
        const holder = e.target.value;
        setFieldValue('accountHolder', holder);
        setFieldValue('accountText', holder);
    };

    const handleExpandPrivacy = (e) => {
        setExpandPrivacy(!expandPrivacy);
    };
    const handleExpandAgreement = (e) => {
        setExpandAgreement(!expandAgreement);

    };

    const handleCheck = (e) => {
        const checked = e.target.checked;
        switch (e.target.name) {
            case 'agreement':
                return setAgreement(checked);
            case 'privacy':
                return setPrivacy(checked);
            default:
                return;
        }
    };

    const handleChangeKakaoAddress = (({postalCode, address1, address2}) => {
        if (!postalCode) {
            return enqueueSnackbar('배송지를 입력해 주십시오', {variant: 'error'});
        }
        if (!availablePostalCodes.has(postalCode)) {
            alert('딸기 특성상 택배 배송이 아닌,\n신선 배송 방식이라 부득이하게 배송 지역 제한이 있습니다.\n양해부탁드립니다.\n(부릉 배송 가능 권역만 가능)');
            return enqueueSnackbar('죄송합니다. 딸기 배송이 불가능한 지역입니다.', {variant: 'error'});
        }
        setValues({
            ...values,
            postalCode,
            address1,
            address2
        });
        return enqueueSnackbar('딸기 배송 가능 지역입니다.', {variant: 'info'});
    });

    return (
        <>
            <Box
                className={classes.bg}
            >
                <Container
                    className={classes.container}
                    maxWidth="md"
                >
                    <Link
                        href={'https://www.bgood.co.kr'}
                    >
                        <Logo className={classes.logo}/>
                    </Link>
                    <Card
                        className={classes.card}
                    >
                        <CardMedia
                            className={classes.cardMedia}
                            image={crowd.img}
                        />
                        <CardContent>
                            {
                                group?.title
                                    ? (
                                        <Typography
                                            className={classes.groupTitle}
                                            variant={'h6'}
                                            color={'primary'}
                                        >
                                            {
                                                group.title
                                            }
                                        </Typography>
                                    )
                                    : null
                            }
                            <Typography
                                className={classes.subtitle}
                                variant={'h5'}
                            >
                                {
                                    crowd.subtitle
                                }
                            </Typography>
                            <Typography
                                className={classes.title}
                                variant={'h4'}
                            >
                                {
                                    crowd.title
                                }
                            </Typography>
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    상품:
                                </Typography>
                                <Typography>
                                    {crowd.product}
                                </Typography>
                            </Box>
                            {
                                !disablePrice
                                    ? (
                                        <Box>
                                            <Typography
                                                variant={'body2'}
                                                className={classes.typo}
                                            >
                                                구매 가격:
                                            </Typography>
                                            <Typography>
                                                {crowd.price.toLocaleString()} 원
                                            </Typography>
                                        </Box>
                                    )
                                    : null
                            }
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    공동 구매 상품:
                                </Typography>
                                <Typography
                                    className={classes.typo}
                                >
                                    {crowd.productDetail}
                                </Typography>
                                <Typography>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    공동구매 모집일:
                                </Typography>
                                <Typography>
                                    {crowd.startedAt} ~ {crowd.endedAt}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    배송 지역:
                                </Typography>
                                {
                                    crowd.deliveryTo
                                        ? (
                                            <Typography>
                                                {crowd.deliveryTo}
                                            </Typography>
                                        )
                                        : (
                                            <>
                                                <Typography>
                                                    서울 및 경기, 인천 일부 지역
                                                </Typography>
                                                <Typography
                                                    variant={'caption'}
                                                >
                                                    (부릉 당일 배송 가능 지역)
                                                </Typography>
                                            </>
                                        )
                                }
                            </Box>
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    배송 시작일:
                                </Typography>
                                <Typography>
                                    {crowd.deliverFrom}
                                </Typography>
                                <Typography
                                    variant={'caption'}
                                    color={'error'}
                                >
                                    산지 출하량에 따라 순차적으로 월, 수, 금에만 발송됩니다.
                                </Typography>
                            </Box>
                            {
                                !(group?.paid)
                                    ? (
                                        <Box>
                                            <Typography
                                                variant={'body2'}
                                                className={classes.typo}
                                            >
                                                결제 방식: 계좌이체
                                            </Typography>
                                            <Typography>
                                                국민은행<br/>
                                                407501-01-204108<br/>
                                                주식회사 에스앤이컴퍼니
                                            </Typography>

                                            <Typography
                                                variant={'caption'}
                                                color={'error'}
                                            >
                                                계좌이체 확인 후 주문이 최종 확정됩니다.
                                            </Typography>
                                        </Box>
                                    )
                                    : null
                            }
                            <Box>
                                <Typography
                                    variant={'body2'}
                                    className={classes.typo}
                                >
                                    문의:
                                </Typography>
                                <Typography>
                                    (주)에스앤이컴퍼니 1666-3812 (월~금 9시~18시)
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                component={Link}
                                href={'/confirm'}
                            >
                                기존 주문 조회 / 취소
                            </Button>
                        </CardContent>
                    </Card>
                    <Card>
                        {/*<AutoPlaySwipeableViews*/}
                        {/*    axis={'x'}*/}
                        {/*    enableMouseEvents*/}
                        {/*>*/}
                        {/*    {Object.values(crowd.gallery).map((imgPath, index) => (*/}
                        {/*        <div key={imgPath}>*/}
                        {/*            <img className={classes.img} src={imgPath} alt={'딸'}/>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</AutoPlaySwipeableViews>*/}
                        <img className={classes.img} src={crowd.gallery['1']} alt={'딸기'}/>
                    </Card>
                    <Card
                        className={classes.card}
                    >
                        <CardHeader
                            title={'주문 정보 입력'}
                        />
                        <CardContent>
                            <Box
                                className={classes.productInfo}
                            >
                                <Typography
                                    variant={'subtitle1'}
                                    component={'span'}
                                >
                                    {crowd.product}
                                </Typography>
                            </Box>
                            <Input
                                className={classes.input}
                                id={'amount'}
                                name={'amount'}
                                label={'주문 수량'}
                                type={'number'}
                                min={1}
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.amount && errors.amount}
                            />
                            {
                                group.comment
                                    ? (
                                        <Input
                                            className={classes.input}
                                            id={'comment'}
                                            name={'comment'}
                                            label={`${group.name}에게 한마디`}
                                            type={'text'}
                                            value={values.comment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.comment && errors.comment}
                                        />
                                    )
                                    : null
                            }
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.card}
                    >
                        <CardHeader
                            title={'주문자 정보 입력'}
                        />
                        <CardContent>
                            <Box
                                className={classes.inputs}
                            >
                                <Input
                                    className={classes.input}
                                    id={'name'}
                                    name={'name'}
                                    label={'주문자 성함'}
                                    type={'text'}
                                    value={values.name}
                                    onChange={handleName}
                                    onBlur={handleBlur}
                                    error={touched.name && errors.name}
                                />
                                <Input
                                    className={classes.input}
                                    id={'tel'}
                                    name={'tel'}
                                    label={'주문자 연락처'}
                                    placeholder={'000-0000-0000'}
                                    type={'text'}
                                    value={values.tel}
                                    onChange={handleTel}
                                    onBlur={handleBlur}
                                    error={touched.tel && errors.tel}
                                />
                                <Input
                                    className={classes.input}
                                    id={'email'}
                                    name={'email'}
                                    label={'주문자 이메일'}
                                    placeholder={'email@email.com'}
                                    type={'text'}
                                    value={values.email}
                                    onChange={handleEmail}
                                    onBlur={handleBlur}
                                    error={touched.email && errors.email}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                    {
                        !crowd?.groupOrder
                            ? (
                                <Card
                                    className={classes.card}
                                >
                                    <CardHeader
                                        title={'배송 정보 입력'}
                                        subheader={
                                            <Typography
                                                className={classes.sectionSubtitle}
                                                color={'error'}
                                                variant={'body2'}
                                            >
                                                현재 서울과 수도권 일부 지역만 배송 가능합니다.<br/>
                                                주소 입력시 배송 가능 여부가 확인됩니다.<br/>
                                                기본 야간 배송되며 배송업체 사정에 따라 익일 새벽배송 될 수 있습니다.
                                            </Typography>
                                        }
                                    />
                                    <CardContent>
                                        <Box mb={2}>
                                            <Input
                                                className={classes.input}
                                                id={'deliveryName'}
                                                name={'deliveryName'}
                                                label={'받는 분 성함'}
                                                type={'text'}
                                                value={values.deliveryName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.deliveryName && errors.deliveryName}
                                            />
                                            <Input
                                                className={classes.input}
                                                id={'deliveryTel'}
                                                name={'deliveryTel'}
                                                label={'받는 분 연락처'}
                                                placeholder={'000-0000-0000'}
                                                type={'text'}
                                                value={values.deliveryTel}
                                                onChange={handleDeliveryTel}
                                                onBlur={handleBlur}
                                                error={touched.deliveryTel && errors.deliveryTel}
                                            />
                                        </Box>
                                        <KakaoAddress
                                            className={classes.input}
                                            id={'postalCode'}
                                            name={'postalCode'}
                                            type={'text'}
                                            label={'우편번호'}
                                            placeholder={'12345'}
                                            value={values.postalCode}
                                            onChange={handleChangeKakaoAddress}
                                            error={touched.postalCode && errors.postalCode}
                                        />
                                        <KakaoAddress
                                            className={classes.input}
                                            id={'address1'}
                                            name={'address1'}
                                            type={'text'}
                                            label={'주소'}
                                            placeholder={'주소 (클릭시 주소 검색창으로 이동합니다.)'}
                                            value={values.address1}
                                            onChange={handleChangeKakaoAddress}
                                            error={touched.address1 && errors.address1}
                                        />
                                        <Input
                                            disabled={!values.address1}
                                            className={classes.input}
                                            id={'address2'}
                                            name={'address2'}
                                            type={'text'}
                                            label={'상세 주소'}
                                            placeholder={'없을 경우 생략 가능합니다.'}
                                            value={values.address2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Input
                                            className={classes.input}
                                            id={'deliveryRequirement'}
                                            name={'deliveryRequirement'}
                                            type={'text'}
                                            label={'새벽 배송 정보(공동현관 비밀번호 등)'}
                                            placeholder={'새벽 배송시 문 앞 배송 요망'}
                                            value={values.deliveryRequirement}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </CardContent>
                                </Card>
                            )
                            : null
                    }
                    {
                        !(group.paid)
                            ? (
                                <Card>
                                    <CardHeader
                                        title={'결제 정보'}
                                        subheader={
                                            <Typography
                                                className={classes.sectionSubtitle}
                                                variant={'body2'}
                                                color={'error'}
                                            >
                                                계좌 이체 내역 확인에 이용됩니다.<br/>
                                                계좌 이체가 확인되어야 주문이 확정 완료됩니다.
                                            </Typography>
                                        }
                                    />
                                    <CardContent>
                                        <Box mb={2}>
                                            <Typography>
                                                이체계좌:<br/>
                                                국민은행 407501-01-204108<br/>
                                                주식회사 에스앤이컴퍼니
                                            </Typography>
                                        </Box>
                                        <Input
                                            select
                                            className={classes.input}
                                            id={'accountBank'}
                                            name={'accountBank'}
                                            label={'은행'}
                                            placeholder={''}
                                            value={values.accountBank}
                                            onChange={handleChange}
                                            error={touched.accountBank && errors.accountBank}
                                        >
                                            {
                                                Object.entries(bankCodes).map(([code, bankName]) => {
                                                    return (
                                                        <MenuItem
                                                            key={code}
                                                            label={bankName}
                                                            value={code}
                                                        >
                                                            {code} {bankName}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Input>
                                        <Input
                                            className={classes.input}
                                            id={'accountHolder'}
                                            name={'accountHolder'}
                                            type={'text'}
                                            label={'예금주명'}
                                            placeholder={''}
                                            value={values.accountHolder}
                                            onChange={handleAccountHolder}
                                            onBlur={handleBlur}
                                            error={touched.accountHolder && errors.accountHolder}
                                        />
                                        <Input
                                            className={classes.input}
                                            id={'accountText'}
                                            name={'accountText'}
                                            type={'text'}
                                            label={'이체 통장 표시 내역(선택)'}
                                            placeholder={''}
                                            value={values.accountText}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.accountText && errors.accountText}
                                        />
                                        <Input
                                            className={classes.input}
                                            id={'billTo'}
                                            name={'billTo'}
                                            type={'text'}
                                            label={'현금영수증 발급번호(선택)'}
                                            placeholder={'휴대폰번호/주민번호'}
                                            value={values.billTo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.billTo && errors.billTo}
                                        />
                                    </CardContent>
                                </Card>
                            )
                            : null
                    }
                    <Card
                        className={classes.card}
                    >
                        <CardHeader
                            title={'공동구매 약관'}
                        />
                        <CardContent>
                            <Box className={classes.codeHead}>
                                <Typography>
                                    서비스 이용약관
                                </Typography>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandAgreement,
                                    })}
                                    onClick={handleExpandAgreement}
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </Box>
                            <Typography
                                variant={'caption'}
                            >
                                에스앤이컴퍼니 비굿 공동구매 이용과 관련하여 본인은 동의내용을 숙지하였으며, 이에 동의합니다.
                            </Typography>
                            <Collapse in={expandAgreement} timeout="auto" unmountOnExit>
                                <Box
                                    mb={4}
                                >
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제1조(목적)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 다음과 같은 업무를 수행합니다.<br/>
                                            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
                                            2. 구매계약이 체결된 재화 또는 용역의 배송<br/>
                                            3. 기타 “에스앤이컴퍼니(비굿)”이 정하는 업무<br/>
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는
                                            용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화
                                            또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로
                                            변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시
                                            통지합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ 전항의 경우 “에스앤이컴퍼니(비굿)”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “에스앤이컴퍼니(비굿)”이 고의 또는
                                            과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제2조(서비스의 제공 및 변경)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 다음과 같은 업무를 수행합니다.<br/>
                                            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
                                            2. 구매계약이 체결된 재화 또는 용역의 배송<br/>
                                            3. 기타 “에스앤이컴퍼니(비굿)”이 정하는 업무<br/>
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는
                                            용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화
                                            또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로
                                            변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시
                                            통지합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ 전항의 경우 “에스앤이컴퍼니(비굿)”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “에스앤이컴퍼니(비굿)”이 고의 또는
                                            과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제3조(구매신청 및 개인정보 제공 동의 등)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”이용자는 “에스앤이컴퍼니(비굿)”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며,
                                            “에스앤이컴퍼니(비굿)”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.<br/>
                                            1. 재화 등의 검색 및 선택<br/>
                                            2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                                            3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
                                            4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)<br/>
                                            5. 재화 등의 구매신청 및 이에 관한 확인 또는 “에스앤이컴퍼니(비굿)”의 확인에 대한 동의<br/>
                                            6. 결제방법의 선택<br/>
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는
                                            자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고
                                            동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는
                                            자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도
                                            같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에
                                            관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제4조(계약의 성립)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 제6조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와
                                            계약을 체결하는
                                            경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>

                                            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                                            2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우<br/>
                                            3. 기타 구매신청에 승낙하는 것이 “에스앤이컴퍼니(비굿)” 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”의 승낙이 제6조제1항의 수신확인통지형태로 이용자에게 도달후 결제가 완료된 시점에 계약이 성립한 것으로
                                            봅니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한
                                            정보 등을 포함하여야
                                            합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제5조(지급방법)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            “에스앤이컴퍼니(비굿)”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단,
                                            “에스앤이컴퍼니(비굿)”은 이용자의
                                            지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.<br/>
                                            1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
                                            2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/>
                                            3. 온라인무통장입금<br/>
                                            4. 전자화폐에 의한 결제<br/>
                                            5. 수령 시 대금지급<br/>
                                            6. 마일리지 등 “에스앤이컴퍼니(비굿)”이 지급한 포인트에 의한 결제<br/>
                                            7. “에스앤이컴퍼니(비굿)”과 계약을 맺었거나 “에스앤이컴퍼니(비굿)”이 인정한 상품권에 의한 결제<br/>
                                            8. 기타 전자적 지급 방법에 의한 대금 지급 등<br/>
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제6조(수신확인통지․구매신청 변경 및 취소)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수
                                            있고 “에스앤이컴퍼니(비굿)”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을
                                            지불한 경우에는
                                            제15조의 청약철회 등에 관한 규정에 따릅니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제7조(재화 등의 공급)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에
                                            재화 등을 배송할 수
                                            있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “에스앤이컴퍼니(비굿)”이 이미 재화 등의 대금의 전부 또는 일부를
                                            받은 경우에는 대금의
                                            전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 “에스앤이컴퍼니(비굿)”은 이용자가 재화 등의 공급 절차 및 진행
                                            사항을 확인할 수
                                            있도록 적절한 조치를 합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약
                                            “에스앤이컴퍼니(비굿)”이 약정
                                            배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “에스앤이컴퍼니(비굿)”이 고의․과실이 없음을 입증한
                                            경우에는 그러하지
                                            아니합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제8조(환급)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”과 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
                                            제2항에 따른
                                            계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이
                                            시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한
                                            법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br/>
                                            1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는
                                            청약철회를 할 수 있습니다)<br/>
                                            2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우<br/>
                                            3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우<br/>
                                            4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우<br/>
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ 제2항제2호 내지 제4호의 경우에 “에스앤이컴퍼니(비굿)”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에
                                            명기하거나 시용상품을
                                            제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화
                                            등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제9조(청약철회 등의 효과)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우
                                            “에스앤이컴퍼니(비굿)”이
                                            이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서
                                            정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한
                                            때에는 지체 없이 당해
                                            결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “에스앤이컴퍼니(비굿)”은 이용자에게 청약철회 등을
                                            이유로 위약금
                                            또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우
                                            재화 등의 반환에 필요한 비용은 “에스앤이컴퍼니(비굿)”이 부담합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “에스앤이컴퍼니(비굿)”은 청약철회 시 그 비용을 누가 부담하는지를 이용자가
                                            알기 쉽도록
                                            명확하게 표시합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제10조(개인정보보호)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여
                                            구매계약 이전에
                                            본인확인이
                                            필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ “에스앤이컴퍼니(비굿)”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게
                                            제공하는 경우에는
                                            이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ⑤ “에스앤이컴퍼니(비굿)”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및
                                            전화번호, 기타
                                            연락처),
                                            정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및
                                            정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ⑥ 이용자는 언제든지 “에스앤이컴퍼니(비굿)”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며
                                            “에스앤이컴퍼니(비굿)”은 이에 대해 지체 없이
                                            필요한
                                            조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “에스앤이컴퍼니(비굿)”은 그 오류를 정정할 때까지 당해 개인정보를
                                            이용하지 않습니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ⑦ “에스앤이컴퍼니(비굿)”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌
                                            등을 포함한 이용자의
                                            개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ⑧ “에스앤이컴퍼니(비굿)” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
                                            개인정보를 지체 없이
                                            파기합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ⑨ “에스앤이컴퍼니(비굿)”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의
                                            수집·이용·제공에 관한
                                            이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을
                                            이유로
                                            회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제11조(“몰“의 의무)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
                                            안정적으로
                                            재화․용역을 제공하는데 최선을 다하여야 합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안
                                            시스템을 갖추어야
                                            합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써
                                            이용자가 손해를
                                            입은 때에는 이를 배상할 책임을 집니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ④ “에스앤이컴퍼니(비굿)”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제12조(이용자의 의무) 이용자는 다음 행위를 하여서는 안 됩니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            1. 신청 또는 변경시 허위 내용의 등록<br/>
                                            2. 타인의 정보 도용<br/>
                                            3. “에스앤이컴퍼니(비굿)”에 게시된 정보의 변경<br/>
                                            4. “에스앤이컴퍼니(비굿)”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
                                            5. “에스앤이컴퍼니(비굿)” 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
                                            6. “에스앤이컴퍼니(비굿)” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                                            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위<br/>
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제13조(연결“에스앤이컴퍼니(비굿)”과 피연결“에스앤이컴퍼니(비굿)” 간의 관계)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① 상위 “에스앤이컴퍼니(비굿)”과 하위 “에스앤이컴퍼니(비굿)”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
                                            포함됨)방식 등으로 연결된 경우,
                                            전자를 연결 “에스앤이컴퍼니(비굿)”(웹 사이트)이라고 하고 후자를 피연결 “에스앤이컴퍼니(비굿)”(웹사이트)이라고 합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② 연결“에스앤이컴퍼니(비굿)”은 피연결“에스앤이컴퍼니(비굿)”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서
                                            보증 책임을 지지 않는다는 뜻을
                                            연결“에스앤이컴퍼니(비굿)”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제14조(저작권의 귀속 및 이용제한)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”이 작성한 저작물에 대한 저작권 기타 지적재산권은 “에스앤이컴퍼니(비굿)”에 귀속합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② 이용자는 “에스앤이컴퍼니(비굿)”을 이용함으로써 얻은 정보 중 “에스앤이컴퍼니(비굿)”에게 지적재산권이 귀속된 정보를
                                            “에스앤이컴퍼니(비굿)”의 사전 승낙 없이 복제, 송신, 출판,
                                            배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제15조(분쟁해결)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
                                            설치․운영합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
                                            경우에는 이용자에게
                                            그 사유와 처리일정을 즉시 통보해 드립니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ③ “에스앤이컴퍼니(비굿)”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
                                            시·도지사가 의뢰하는
                                            분쟁조정기관의 조정에 따를 수 있습니다.
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={4}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            제16조(재판권 및 준거법)
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ① “에스앤이컴퍼니(비굿)”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
                                            경우에는 거소를 관할하는
                                            지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
                                            관할법원에 제기합니다.
                                        </Typography>
                                        <Typography
                                            variant={'caption'}
                                            gutterBottom
                                        >
                                            ② “에스앤이컴퍼니(비굿)”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant={'caption'}
                                >
                                    본 동의는 에스앤이컴퍼니 비굿 공동구매 서비스 이용을 위해 필요한 동의로서, 동의하지 않으실 경우 서비스를 이용하실 수 없습니다.
                                </Typography>
                            </Collapse>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <CheckLabel
                                    name={'agreement'}
                                    checked={agreement}
                                    onCheck={handleCheck}
                                >
                                    <Typography
                                        variant={'body2'}
                                        component={'span'}
                                    >
                                        서비스 이용약관에 동의합니다.
                                    </Typography>
                                </CheckLabel>
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box className={classes.codeHead}>
                                <Typography>
                                    개인정보처리방침
                                </Typography>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandPrivacy,
                                    })}
                                    onClick={handleExpandPrivacy}
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </Box>
                            <Typography
                                variant={'caption'}
                            >
                                에스앤이컴퍼니 비굿 공동구매 이용과 관련하여 본인은 동의내용을 숙지하였으며, 이에 따라 본인의 개인정보를 주문, 배송 등 서비스 이용을 위한 이용자
                                식별, 본인 여부 등의 확인을 목적으로 아래의 에스앤이컴퍼니와 아래의 제3자 간 제공하는 것에 동의합니다.
                            </Typography>
                            <Collapse in={expandPrivacy} timeout="auto" unmountOnExit>
                                <Box
                                    mt={4}
                                    mb={4}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        제공받는 자
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        수집목적
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        항목
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        보유기간
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        주식회사 에스앤이컴퍼니
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        비굿 공동구매 서비스 제공
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        이름, 이메일, 휴대전화번호, 주소, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 계좌정보, 현금영수증
                                                        발급번호
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        -서비스 이용계약 또는 회원가입 해지시까지, 다만 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지<br/>
                                                        -전자상거래에서의 계약·청약철회, 대금결제, 재화 등 공급기록 : 5년<br/>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        주식회사 메쉬코리아
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        송장부착 및 배송, 배송 안내
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        주자 성명, 연락처, 주소, 주문 사항
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant={'caption'}
                                                    >
                                                        배송 완료시까지
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Typography
                                    variant={'caption'}
                                >
                                    본 동의는 에스앤이컴퍼니 비굿 공동구매 서비스 이용을 위해 필요한 동의로서, 동의하지 않으실 경우 서비스를 이용하실 수 없습니다.
                                </Typography>
                            </Collapse>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <CheckLabel
                                    name={'privacy'}
                                    checked={privacy}
                                    onCheck={handleCheck}
                                >
                                    <Typography
                                        variant={'body2'}
                                        component={'span'}
                                    >
                                        개인정보처리방침에 동의합니다.
                                    </Typography>
                                </CheckLabel>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.actions}
                    >
                        <CardContent
                        >
                            <Box
                                className={classes.actionContent}
                            >
                                <Typography
                                    className={classes.price}
                                    variant={'subtitle1'}
                                    component={'span'}
                                >
                                    {
                                        !disablePrice
                                            ? <span>총 {(crowd.price * values.amount).toLocaleString()}원</span>
                                            : null

                                    }
                                </Typography>
                                <Button
                                    onClick={handleSubmit}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={
                                        hasError
                                        || !privacy
                                        || !agreement
                                    }
                                >
                                    신청하기
                                </Button>
                            </Box>
                            <Box
                                mt={1}
                            >
                                <Typography
                                    variant={'body2'}
                                    color={'error'}
                                    align={'right'}
                                >
                                    {
                                        !hadTouched
                                            ? '주문 정보가 필요합니다.'
                                            : hasError
                                            ? Object.values(errors)[0]
                                            : !agreement
                                                ? '이용약관 동의가 필요합니다.'
                                                : !privacy
                                                    ? '개인정보처리방침 동의가 필요합니다.'
                                                    : null
                                    }
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box
                        className={classes.bottom}
                    >
                        <Link
                            href={company.url}
                            underline={'always'}
                        >
                            <Typography
                                variant={'caption'}
                                color={'textSecondary'}
                                component={'div'}
                            >
                                {company.name}
                            </Typography>
                        </Link>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.number}
                                </Typography>
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.register}
                                </Typography>
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.responsibility}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.tel}
                                </Typography>
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.email}
                                </Typography>
                                <Typography
                                    variant={'caption'}
                                    color={'textSecondary'}
                                    component={'div'}
                                >
                                    {company.address}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            {/*{*/}
            {/*    !disableModal*/}
            {/*        ? (*/}
            {/*            <Modal*/}
            {/*                title={'“못난이(비굿) 딸기, 가치 드실래요?”'}*/}
            {/*                content={*/}
            {/*                    <Box>*/}
            {/*                        <Typography>*/}
            {/*                            안녕하세요. 농산물 직거래 플랫폼 비굿(B·good)입니다.<br/>*/}
            {/*                            비굿은 기존 농산물 유통의 다단계 과정에서 발생하는 비용 거품을 걷어내고 거래이익을 생산자인 농민과 소비자 분들에게 돌려주자는 게 사업의 핵심입니다.*/}
            {/*                            특히 저희는*/}
            {/*                            ‘못난이(B품) 농산물’에 주목했습니다. 못난이 농산물은 일반 농산물과 맛과 질에서 차이가 없지만 모양 등이 불규칙하다는 이유로 상품성을 인정받지*/}
            {/*                            못하는 우리*/}
            {/*                            농산물입니다.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*                            이번에 공동구매를 추진하는 못난이 딸기의 품종은 매향입니다. 내수용으로는 설향이 주력이라면 수출용으로는 매향이 그 역할을 하고 있습니다. 크기와 모양*/}
            {/*                            등이 수출용*/}
            {/*                            규격에 맞지 않아 비규격품(못난이)으로 분류된 것입니다.*/}
            {/*                            <Link*/}
            {/*                                href={'https://www.youtube.com/channel/UCZn4HjtjG2bDjaUmgjPXsWQ'}*/}
            {/*                                underline={'always'}*/}
            {/*                            >*/}
            {/*                                동영상*/}
            {/*                            </Link>*/}
            {/*                            은 가격이 저렴하다는 점 외에 어떤 딸기가 어떻게 전달되는지 궁금해 하실 수 있는 분들을 위해 제작했습니다.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*            <span className={classes.bold}>*/}
            {/*            # 건강함과 희소성도 함께 담았습니다.*/}
            {/*            </span>*/}
            {/*                            <br/>*/}
            {/*                            수출 대상 국가의 통관 기준에 따라 잔류농약 등도 없어야 하기 때문에 딸기농가에서는 씻지 않고 드셔도 무방하다고 얘기합니다. 특히 국내에서는 드시기*/}
            {/*                            힘든 딸기라는*/}
            {/*                            희소성도 있습니다.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*            <span className={classes.bold}>*/}
            {/*            # 무르거나 상할 걱정을 덜어드렸습니다.*/}
            {/*            </span>*/}
            {/*                            <br/>*/}
            {/*                            딸기는 수확 후 익일 배송됩니다. 역으로 보면 더 오래 보관할 수 있습니다. 오히려 상품에 포함된 미숙(덜 익은) 딸기는 며칠 보관하는 후숙 과정을*/}
            {/*                            거친 뒤 드시면*/}
            {/*                            훨씬 더 맛있습니다. 미숙 딸기는 상온에서 1~3일 정도 보관하셔도 무방합니다.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*            <span className={classes.bold}>*/}
            {/*            # 그럼에도 양해를 부탁드립니다.*/}
            {/*            </span>*/}
            {/*                            <br/>*/}
            {/*                            딸기가 원물이다 보니 문제가 있는 상품이 일부 섞여들어갈 수 있을 듯 합니다. 이는 마트에서 구매하는 일반 농산물에서도 흔히 접할 수 있는 문제입니다.*/}
            {/*                            저희에게*/}
            {/*                            피드백을 주시면 농가와 협의해 유사한 문제가 발생하지 않도록 개선해 나가겠습니다.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*                            새해에도 건강하시고, 뜻하시는 바 이뤄나가시는 한 해 되시길 기원하겠습니다.<br/>*/}
            {/*                            새해 복 많이 받으세요.*/}
            {/*                        </Typography>*/}
            {/*                        <br/>*/}
            {/*                        <Typography>*/}
            {/*                            비굿 올림*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                }*/}
            {/*            />*/}
            {/*        )*/}
            {/*        : null*/}
            {/*}*/}
        </>
    );
}
