import React, {Suspense} from 'react';
import {Switch} from 'react-router';
import {BrowserRouter as Router} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';

import routes from './config/routes';
import Route from './components/Route';


export default function App() {
    return (
        <SnackbarProvider>
            <Router>
                <Switch>
                    {
                        routes.map(({path, layout, page, exact, ...route}, idx) => {
                            return <Route
                                key={path}
                                path={path}
                                page={page}
                                exact={exact}
                                {...route}
                            />;
                        })
                    }
                </Switch>
            </Router>
        </SnackbarProvider>
    );
}
