import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {Typography} from '@material-ui/core';

const Input = ({
                   className, inputClassName,
                   id, type,
                   placeholder, label,
                   error, disabled,
                   onChange, value,
                   ...props
               }) => {

    return (
        <Box
            className={className}
        >
            <TextField id={id}
                       className={inputClassName}
                       {...props}
                       fullWidth
                       disabled={disabled}
                       type={type}
                       label={label}
                       helperText={!disabled ? error?.message ?? '' : ''}
                       placeholder={placeholder}
                       value={value}
                       error={!disabled && !!error}
                       variant={'outlined'}
                       onChange={onChange}
            />
            {
                error
                    ? (
                        <Typography
                            variant={'caption'}
                            color={'error'}
                        >
                            {error}
                        </Typography>
                    )
                    : null
            }
        </Box>
    );
};

Input.defaultProps = {
    value: ''
};

export default Input;
