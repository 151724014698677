import React from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {},
    paper: {
        padding: theme.spacing(2, 0)
    },
    headerBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    scrollArea: {
        width: '100%',
        height: '65vh'
    },
    title: {
        fontWeight: '700',
        padding: theme.spacing(0, 2)
    },
    content: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 2)
    }
}));

export default function TransitionsModal({title, content}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Container
                    className={classes.container}
                    maxWidth={'md'}
                >
                    <Paper
                        className={classes.paper}
                    >
                        <Box className={classes.headerBar}>
                            <IconButton
                                onClick={handleClose}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <Box
                            className={classes.scrollArea}
                        >
                            <PerfectScrollBar>
                                <Typography
                                    className={classes.title}
                                    variant={'h6'}
                                >
                                    {title}
                                </Typography>
                                <Box className={classes.content}>
                                    {content}
                                </Box>
                            </PerfectScrollBar>
                        </Box>
                    </Paper>
                </Container>
            </Fade>
        </Modal>
    );
}