import React from 'react';
import {
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

function CheckLabel({className, children, checked, onCheck, name, color}) {
    return (
        <FormControlLabel
            className={className}
            name={name}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onCheck}
                    name={name}
                    color={color}
                />
            }
            label={
                children
            }/>
    )
}

export default CheckLabel